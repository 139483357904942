.container {
  box-sizing: border-box;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  background-color: rgb(173, 183, 245);
}
.header {
  position: absolute;
  font-size: 40px;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: aliceblue;
  text-shadow: 2px 2px 1px black;
}

.contentContainer {
  padding: 50px;
}

.contentDiv {
  display: grid;
  grid-template-columns: auto 45%;
  grid-row: auto auto auto auto;
  padding: 20px;
}

.contentHeader {
  grid-column: 1/3;
  text-align: center;
  color: #00356f;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 40px;
}

.content {
  grid-row: 2/3;
  grid-column: 1/2;
  padding: 8%;
  font-size: 22px;
  text-align: center;
  font-weight: 450;
}

.imageDiv {
  grid-row: 2/3;
  grid-column: 2/3;
}

.imageDiv img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.workExperience {
  grid-column: 2/3;
  grid-row: 3/4;
  padding: 10%;
}

#workHeader {
  padding: 30px;
  margin-bottom: 0;
  text-decoration: underline;
  background: linear-gradient(45deg, rgb(235, 235, 171), rgb(236, 162, 25));
  border-radius: 25px;
  margin-bottom: 20px;
}

#workContent {
  padding-top: 0;
}
#workContent p {
  margin: 0;
  line-height: 31px;
}

.videoDiv {
  grid-column: 1/2;
  grid-row: 3/4;
  padding: 20px;
  background: linear-gradient(45deg, grey, rgb(44, 43, 43));
  border-radius: 10px;
}

.videoDiv video {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.dwsProject {
  grid-column: 1/2;
  grid-row: 3/4;
  padding: 10px;
  background: linear-gradient(45deg, grey, rgb(44, 43, 43));
  border-radius: 10px;
}
.dwsProject img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

#workExperience2 {
  grid-column: 1/2;
  grid-row: 4/5;
}

#workVideo2 {
  grid-column: 2/3;
  grid-row: 4/5;
  background: linear-gradient(45deg, rgb(75, 173, 230), rgb(189, 132, 27));
}

/* for small devices */
@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .contentContainer {
    padding: 30px 10px 10px;
    /* border: 5px solid brown; */
    box-sizing: border-box;
    background-color: rgb(204, 202, 202);
  }
  .contentDiv {
    display: flex;
    flex-direction: column;
    padding: 0px;
    box-sizing: border-box;
    /* border: 5px solid blue; */
  }
  .contentHeader {
    margin: 0;
    font-size: 30px;
    border-radius: 20px;
    box-shadow: 6px 6px 2px rgb(66, 54, 175);
    background: linear-gradient(45deg, rgb(136, 196, 136), rgb(233, 233, 157));
  }

  .content {
    box-shadow: 5px 5px 3px salmon;
    padding: 5px;
    margin: 20px 0px 20px;
    border-radius: 25px;
    background-color: rgb(139, 223, 226);
  }
}
