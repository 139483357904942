.container {
  text-align: center;
  color: aliceblue;
  height: 100vh;
  font-size: 60px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-weight: 600;
  text-shadow: 2px 2px 1px black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  /* background-color: rgba(31, 30, 30, 0.534); */
  /* border: 5px solid blue; */
  box-sizing: border-box;
}

.paraDiv {
  width: 600px;
  padding: 30px;
  background: rgba(59, 59, 59, 0.534);
  border-radius: 35px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.paraDiv p {
  margin: 0;
}

.overlay {
  width: 30px;
  height: 30px;
  /* border: 1px solid green; */
  position: absolute;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

#overlay1 {
  background-color: rgb(182, 209, 61);
  animation: 3s linear 0s infinite alternate sun-rise1;
}

#overlay2 {
  background-color: rgb(60, 163, 194);
  animation: 3s linear 0s infinite alternate sun-rise2;
}

#overlay3 {
  background-color: rgb(209, 61, 142);
  animation: 3s linear 0s infinite alternate sun-rise3;
}

@keyframes sun-rise1 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(60vw, 50vh);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(0vw, -10vh);
  }
}

@keyframes sun-rise2 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(30vw, 50vh);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(20vw, -10vh);
  }
}

@keyframes sun-rise3 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(50vw, -10vh);
    background-color: rgb(230, 22, 202);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(0vw, 60vh);
    /* background-color: rgb(52, 139, 197); */
  }
}

@media screen and (max-width: 768px) {
  .container {
    font-size: 30px;
  }
  .paraDiv {
    width: 70%;
    /* height: 200px; */
    box-shadow: 2px 2px 1px green;
  }
}
