.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure overlay is above other content */
}

.cardContainer {
  /* border: 2px solid blue; */
  width: 300px;
  /* height: 350px; */
  margin: 8px;
  border-radius: 25px;
  box-sizing: border-box;
  padding: 20px;
}

.header {
  /* border-bottom: 0.5px solid black; */
  text-align: center;
  margin-bottom: 20px;
}
.header h2 {
  margin: 0;
}
.header p {
  margin: 10px;
  font-weight: 480;
  font-size: 17px;
}

.cardBody {
  display: grid;
  grid-template-columns: 90px 100px;
  grid-template-areas: "price1 data1" "price2 data2";
  padding: 10px 20px;
  text-align: left;
  grid-column-gap: 30px;
}

#price1 {
  grid-area: price1;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
#price2 {
  grid-area: price2;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

#data1 {
  grid-area: data1;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
#data2 {
  grid-area: data2;
  margin: 0;
}

.footer {
  text-align: center;
  margin-top: 37px;
}

.footer button {
  width: 90%;
  background-color: #0f3cc9;
  color: #fff;
  font-size: 18px;
  border: 0;
  border-radius: 15px;
  height: 35px;
}

.footer button:hover {
  background-color: rgb(10, 10, 128);
  cursor: pointer;
}
