.container {
  box-sizing: border-box;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}
.header {
  position: absolute;
  font-size: 40px;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: aliceblue;
  text-shadow: 2px 2px 1px black;
}

.contentContainer {
  /* border: 5px solid green; */
  display: grid;
  grid-template-columns: 55% 45%;
  padding: 50px;
  background-color: rgb(196, 233, 255);
  text-align: center;
  row-gap: 50px;
}

.contentDiv {
  /* border: 5px solid brown; */
  grid-column: 1/2;
  padding: 20px;
}

.contentDiv h1,
.contentDiv h2 {
  background: linear-gradient(45deg, rgb(241, 241, 106), red);
  color: aliceblue;
  padding: 5px;
  text-shadow: 1px 1px 0.5px black;
  border-radius: 15px;
}

.contentDiv p,
.contentDiv2 p {
  font-size: 22px;
}

.contentDiv2 h1,
.contentDiv2 h2 {
  background: linear-gradient(45deg, rgb(241, 241, 106), red);
  color: aliceblue;
  padding: 5px;
  text-shadow: 1px 1px 0.5px black;
  border-radius: 15px;
}

.videoDiv {
  grid-column: 2/3;
  grid-row: 1/2;
  padding: 20px;
  background: linear-gradient(45deg, grey, rgb(44, 43, 43));
  border-radius: 10px;
}

.videoDiv video {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.contentDiv2 {
  grid-column: 2/3;
  grid-row: 2/3;
  /* border: 5px solid brown; */
  padding: 20px;
}
.imageDiv {
  /* border: 5px solid blue; */
  grid-column: 1/2;
  grid-row: 2/3;
  padding: 5%;
  background: linear-gradient(45deg, skyblue, orange);
  border-radius: 25px;
}

.imageDiv img {
  width: 100%;
  height: 60vh;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
  }

  .contentDiv p {
    /* border: 5px solid brown; */
    font-size: 18px;

    padding: 5px;
  }
  .contentDiv2 {
    /* border: 5px solid brown; */
    padding: 5px;
  }

  .contentDiv2 p {
    /* border: 5px solid brown; */
    font-size: 18px;
    padding: 5px;
  }

  .videoDiv {
    padding: 10px;
  }
  .imageDiv img {
    width: 100%;
    height: 45vh;
  }
}
