.container {
  box-sizing: border-box;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}
.header {
  position: absolute;
  font-size: 40px;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: aliceblue;
  text-shadow: 2px 2px 1px black;
}

.contentContainer {
  /* border: 5px solid green; */
  display: grid;
  grid-template-columns: 55% 45%;
  padding: 50px;
  background-color: rgb(196, 233, 255);
}

.contentDiv {
  /* border: 5px solid brown; */
  grid-column: 1/2;
  text-align: center;
  padding-right: 20px;
}

.contentDiv h1 {
  background: linear-gradient(45deg, green, orange);
  color: aliceblue;
  text-decoration: none;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 4px 4px 2px black;
}

.projectStatus {
  background: linear-gradient(45deg, green, orange);
  color: aliceblue;
  text-decoration: none;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 4px 4px 2px black;
  font-weight: 700;
  padding: 5px;
}

.contentDiv p {
  padding: 0px 50px 0px;
  font-size: 20px;
  border: 5px solid green;
  background: linear-gradient(45deg, salmon, rgb(207, 196, 97));
  border-radius: 20px;
}

.imageDiv {
  /* border: 5px solid blue; */
  grid-column: 2/3;
  padding: 5%;
  background: linear-gradient(45deg, black, grey);
  border-radius: 25px;
}

.imageDiv img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    /* border: 5px solid blue; */
    box-sizing: border-box;
    padding: 10px;
  }

  .contentDiv h1 {
    font-size: 28px;
  }

  .contentDiv {
    padding: 0px;
  }
  .contentDiv p {
    padding: 10px;
    border: 5px solid green;
    background: linear-gradient(45deg, salmon, rgb(207, 196, 97));
    border-radius: 20px;
  }
}
