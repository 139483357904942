.container {
  width: 100%;
  /* border: 5px solid brown; */
}

.container img {
  width: 100%;
  height: 90vh;
  border-radius: 50px;
  box-shadow: 0px 6px 3px rgb(17, 136, 216);
}

@media screen and (max-width: 768px) {
  .container img {
    height: 55vh;
  }
}
