.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(150, 26, 26);
  /* background: linear-gradient(45deg, black, grey); */
  height: 100vh;
  position: relative;
}

.container img {
  position: absolute;
  z-index: -1;
}

.container h1 {
  font-size: 200px;
  margin: 0px;
}

.container h2 {
  font-size: 50px;
  margin: 0px;
}

.container p {
  font-size: 30px;
}

.container button {
  border: none;
  font-size: 20px;
  background-color: rgb(22, 92, 133);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.container button:hover {
  background-color: rgb(133, 22, 133);
}

.container button a {
  color: aliceblue;
  text-decoration: none;
}
