.container {
  position: relative;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  background-color: rgb(194, 221, 151);
}

.header {
  text-align: center;
  margin: 0;
  padding: 20px;
  position: absolute;
  z-index: 1;
  /* width: 100%; */
  left: 50%;
  transform: translateX(-50%);
  color: aliceblue;
  font-size: 56px;
  text-shadow: 2px 2px 1px black;
}

.contentContainer {
  display: grid;
  grid-template-columns: auto 45%;
  padding: 50px 30px 50px;
}

.content {
  grid-column: 1/2;
  text-align: center;
  padding: 10%;
}

.content h1 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10%;
}
/* .content img {
  width: 100px;
  height: 100px;
} */

.content h3 {
  color: rgb(48, 45, 45);
  margin-top: -10%;
}

.content p {
  font-size: 20px;
}

.videoDiv {
  grid-column: 2/3;
  /* border: 1px solid red; */
  padding: 20px;
  background: linear-gradient(45deg, rgb(231, 62, 146), rgb(167, 91, 167));
  border-radius: 25px;
}
.videoDiv video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .content {
    padding: 0px;
  }

  .content img {
    width: 150px;
  }

  .videoDiv {
    padding: 10px;
  }
}
