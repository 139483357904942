.container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(173, 173, 236);
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 64px;
  }
}
