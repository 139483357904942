.container {
  /* border: 1px solid brown; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: #0e0d0d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
  justify-content: center;
  animation-name: img_animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  background-color: rgb(248, 246, 215);
}

@keyframes img_animation {
  from {
    transform: translate(100vw, 100vh);
  }
  to {
    transform: translate(0);
  }
}

.aboutUs {
  text-align: center;
  color: #555;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  background-color: #afb836;

  box-sizing: border-box;
  /* box-shadow: 0 2px 0.3cm gold; */
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .aboutUs {
    margin-top: 62px;
  }
}
.aboutUs h1 {
  margin: 0px;
  padding: 5px;
  font-size: 45px;
}
.header {
  font-weight: 700;
  color: #222;
  font-size: 30px;
  text-align: center;
}

.content {
  width: 90%;
}

.bottomContainer {
  text-align: center;
}

.paraContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.bottomPara1 {
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  direction: ltr;
  margin: 0px 0px 0px 0px;
}

.bottomPara2 {
  /* color: #757575; */
  text-align: center;
  margin: 0px;
  color: green;
}
