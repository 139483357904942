.container {
  /* margin-bottom: 100px; */
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.birthDay {
  background: linear-gradient(45deg, black, grey);
  display: grid;
  grid-template-columns: 55% 45%;
  text-align: center;
  color: aliceblue;
}
.birthdayContent h1 {
  grid-column: 1/3;
  margin-top: 0;
  font-size: 56px;
  font-weight: 700;
}

.birthdayCarousel {
  grid-column: 1/2;
  padding: 10%;
  padding-right: 0%;
}

.birthdayContent {
  grid-column: 2/3;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.birthdayContent img {
  width: 100%;
  height: 60vh;
  border-radius: 50px;
  box-shadow: 0px 6px 3px rgb(17, 136, 216);
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .birthDay {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .birthdayContent h1 {
    font-size: 30px;
    background: linear-gradient(45deg, rgb(39, 107, 107), rgb(147, 54, 170));
    padding: 10px;
    border-radius: 20px;
  }

  .birthdayContent {
    padding: 10px;
  }

  .birthdayContent img {
    width: 100%;
    height: 45vh;
    border-radius: 50px;
    box-shadow: 0px 6px 3px rgb(17, 136, 216);
  }

  /* .birthdayCarousel {
    margin-right: 10%;
  } */

  .birthdayCarousel {
    padding: 10px;
    padding-right: 10px;
    width: 95%;
  }
}
