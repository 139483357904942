.container {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
.image {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    height: 55vh;
  }
}
