.container {
  /* border: 2px solid brown; */
  padding: 20px;
  padding-bottom: 35px;
}

.header {
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;

  box-shadow: 4px 4px 3px rgb(6, 156, 226);
  background-color: aliceblue;
  padding: 10px;
  border-radius: 20px;
}

.cardContainer {
  /* border: 2px solid green; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 20px 0px 20px;
}

.staticContainer {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(45deg, grey, black);
  color: aliceblue;
  font-size: 18px;
  border-radius: 10px;
}

.staticContainer p {
  margin: 4px;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    /* border: 2px solid green;  */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 15px;
    margin: 20px 0px 20px;
  }

  .staticContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: linear-gradient(45deg, grey, black);
    color: aliceblue;
    font-size: 18px;
    border-radius: 10px;
  }
}
