.card {
  width: 300px;
  box-shadow: 0px 4px 8px 0 rgba(85, 177, 146, 0.945);

  transition-property: box-shadow;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  /* border: 1px solid black; */
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: linear-gradient(45deg, #024884, #c11d24);
  box-sizing: border-box;
  margin: 15px 5px 15px;
}

.card:hover,
.card:focus {
  /* box-shadow: 0px 12px 20px 0 rgba(14, 13, 13, 0.582); */
  box-shadow: 0px 8px 16px 0 rgb(206, 19, 223);
}

@media screen and (max-width: 768px) {
  .card {
    width: 90%;
  }
}

.circle_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  /* border: 0.5px solid black; */
}

.cardBody {
  text-align: center;
  line-height: 31px;
  color: whitesmoke;
  font-weight: 400;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
}

.header {
  line-height: 10px;
  font-size: 14px;
}

.personName {
  font-weight: 650;
  color: #222;
}
