.cardContainer {
  width: 60%;
  height: 400px;
  box-sizing: border-box;
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  position: relative;
  /* border: 5px solid brown; */
  text-shadow: 2px 2px 1px black;
  background: rgba(24, 23, 23, 0.445);
  padding: 5%;
  border-radius: 50px;
}

.cardHeader {
  font-size: 40px;
  color: #fff;
  font-weight: 400;
}

.cardBody {
  font-size: 30px;
  color: #fff;
  font-weight: 300;
}

.btn {
  border: none;
  background-color: #fff;
  color: #00356f;
  font-size: 18px;
  padding: 8px 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn:hover {
  background-color: #00356f;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    text-align: center;
    /* border: 5px solid brown; */
    padding: 5px;
    border-radius: 25px;
  }

  .cardHeader {
    font-size: 25px;
  }

  .cardBody {
    font-size: 18px;
  }
  .btn {
    font-size: 15px;
    padding: 8px 18px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
}
