.container {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  padding: 30px 0px;
  box-sizing: border-box;
  position: relative;

  background: linear-gradient(45deg, #232526, #414345);
  background: linear-gradient(145deg, #403b4a, #e7e9bb);
}

.bgLogo {
  position: absolute;
  z-index: 1;
  opacity: 0.2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  box-sizing: border-box;
  padding: 0 100px;
  font-style: normal;
  font-weight: 450;
  font-size: 22px;
  line-height: 31px;
  color: #070707;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  font-size: 36px;
  line-height: 46px;
  color: #00356f;

  color: aliceblue;
  font-weight: 450;
  text-align: center;
  margin: 0;
  padding: 20px;
  width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /* background: linear-gradient(45deg, yellow, orange 45%); */
  background: linear-gradient(45deg, #e9d362, #333333);
  border-radius: 15px;
  /* box-shadow: 4px 4px 2px rgb(52, 60, 180); */
  box-shadow: 0px 2px 4px #0e0d0d;
}

.para1 {
  position: relative;
  top: -10%;
  left: -5%;
  width: 400px;
  text-align: center;

  transform: rotate(-10deg) translateY(15px);
  margin-bottom: 80px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 4px 4px 8px 0px #00356f;
  border-radius: 10px;
  transition: box-shadow 0.3s;
  /* background-color: rgb(238, 236, 236); */
  background: linear-gradient(45deg, #00467f, #a5cc82);
  color: aliceblue;
}
.para1 p {
  margin: 0;
}
.para1:hover {
  box-shadow: 7px 7px 15px 0px #00356f;
}

.para2 {
  position: relative;
  top: -10%;
  left: 5%;
  width: 350px;
  text-align: center;
  /* background-color: rgb(238, 236, 236); */
  transform: rotate(10deg) translateY(60px);
  margin-bottom: 100px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 4px 4px 8px 0px #c11d24;
  border-radius: 10px;
  transition: box-shadow 0.3s;
  background: linear-gradient(45deg, #bbd2c5, #536976);

  color: aliceblue;
}
.para2 p {
  margin: 0;
}
.para2:hover {
  box-shadow: 7px 7px 15px 0px #c11d24;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 30px 10px;
    box-sizing: border-box;
  }
  .header {
    font-size: 30px;
    line-height: 35px;
    /* color: #00356f; */
    font-weight: 450;
    text-transform: capitalize;
    text-align: center;
    margin: 0;
    width: 90%;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 5px solid brown; */
    padding: 50px 0px;
    font-size: 20px;
  }

  .para1 {
    top: 0%;
    left: 0%;
    width: 100%;
    transform: rotate(0deg) translateY(0px);
    border-radius: 25px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 8px 0px #00356f;
  }

  .para2 {
    position: relative;
    top: 0%;
    left: 0%;
    width: 100%;
    transform: rotate(0deg) translateY(0px);
    margin-bottom: 0px;
    border-radius: 25px;
    box-shadow: 0px 4px 8px 0px #c11d24;
  }

  .bgLogo {
    width: 90%;
  }
}
