.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(45deg, rgb(167, 211, 167), rgb(94, 196, 214));
  padding: 30px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
}

.container h1 {
  margin: 0;
  padding-bottom: 30px;
  color: aliceblue;
  font-size: 56px;
  text-shadow: 2px 2px 1px black;
  text-decoration: underline;
}
.formContainer {
  width: 600px;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(238, 237, 237);
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(23, 24, 24, 0.8);
  margin-bottom: 50px;
}
.formElements {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.formElements input {
  height: 30px;
  padding: 4px;
  font-size: 15px;
  margin: 5px 5px 5px 0px;
  border-radius: 10px;
  border: 0.2px solid rgb(206, 206, 206);
}

.formElements input:focus {
  box-shadow: 4px 4px 3px skyblue;
  border: none;
  outline: none;
}

.formElements textarea {
  padding: 4px;
  font-size: 15px;
  margin: 5px 5px 5px 0px;
  border-radius: 10px;
  border: 0.2px solid rgb(206, 206, 206);
}

.formElements textarea:focus {
  box-shadow: 4px 4px 3px skyblue;
  border: none;
  outline: none;
}

.btn {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.btn input {
  font-size: 18px;
  padding: 8px;
  border-radius: 10px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
}

#submit {
  background-color: rgb(110, 66, 229);
  color: aliceblue;
}

#submit:hover {
  background-color: rgb(68, 27, 179);
}

#close {
  background-color: rgb(230, 28, 78);
  color: aliceblue;
}

#close:hover {
  background-color: rgb(136, 11, 42);
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
    padding: 15px;
  }
  .formContainer {
    width: 100%;
  }
}
