.container {
  color: aliceblue;
  box-sizing: border-box;
  /* background: linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  background: linear-gradient(45deg, #024884, #c11d24); */
}

.container h1 {
  font-weight: 450;
  margin: 0;
  text-align: center;
  padding: 5px;
  font-size: 22px;
}
