.container {
  background-color: rgb(23, 36, 27);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.image1 {
  width: 50%;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
  .image1 {
    width: 100%;
    height: 55vh;
    object-fit: fill;
  }
}
