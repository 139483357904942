.cardContainer {
  width: 80%;
  padding: 20px;
  margin: 25px;
  border-radius: 30px;
  background: linear-gradient(#0f2027, #203a43, #2c5364);
}

#heading {
  color: aliceblue;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    width: 80%;
    margin-top: 6%;
  }
  h1 {
    font-size: 25px;
  }
}
