.formContainer {
  width: 500px;
  padding: 20px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  border-radius: 20px;
  background-color: rgb(238, 237, 237);
  box-sizing: border-box;
  z-index: 1001;
  box-shadow: 0 2px 10px rgba(23, 24, 24, 0.8);
}
.formElements {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.formElements input {
  height: 30px;
  padding: 4px;
  font-size: 15px;
  margin: 5px 5px 5px 0px;
  border-radius: 10px;
  border: 0.2px solid rgb(206, 206, 206);
}

.formElements input:focus {
  box-shadow: 4px 4px 3px skyblue;
  border: none;
  outline: none;
}

.btn {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.btn input {
  font-size: 18px;
  padding: 8px;
  border-radius: 10px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
}

#submit {
  background-color: rgb(110, 66, 229);
  color: aliceblue;
}

#submit:hover {
  background-color: rgb(68, 27, 179);
}

#close {
  background-color: rgb(230, 28, 78);
  color: aliceblue;
}

#close:hover {
  background-color: rgb(136, 11, 42);
}

@media screen and (max-width: 768px) {
  .formContainer {
    width: 80%;
  }
}
