.video_background_container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  /* border: 10px solid blue; */
  box-sizing: border-box;
}

.video_background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* border: 10px solid #c11d24; */
  box-sizing: border-box;
}

.video_background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 1;
  top: 25%;
}

@media screen and (max-width: 768px) {
  .video_background_container {
    position: relative;
    height: 55vh;
    width: 100%;
    box-sizing: border-box;
  }

  .video_background {
    width: 100%;
    height: 100%;
    /* border: 5px solid red; */
    position: absolute;
    box-sizing: border-box;
  }
  .video_background video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: relative;
    z-index: 1;
    top: 0%;
    box-sizing: border-box;
    height: 100%;
    /* border: 10px solid blueviolet; */
  }
}
