.container {
  background: linear-gradient(45deg, #024884, #c11d24);
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  color: aliceblue;
  box-sizing: border-box;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 50px 15px 50px;
}

.companyName {
  font-weight: 700;
  font-size: 45px;
}

.teleCom {
  text-align: left;
  direction: ltr;
  margin: 0;
  padding: 0;
}

#legalRights {
  border-top: 1px solid white;
  text-align: center;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .contentContainer {
    display: flex;
    flex-direction: column;
    /* border: 5px solid blue; */
    padding: 20px;
    padding-top: 0px;
  }

  .companyName {
    font-weight: 700;
    font-size: 35px;
  }
}
