.navbar {
  background: linear-gradient(45deg, #024884, #c11d24);
  padding: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  box-sizing: border-box;
}
.navbarList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbarItem {
  margin-right: 3rem;
  cursor: pointer;
  position: relative;
  font-size: 20px;
}

.navbarItem a {
  color: #fff;
  text-decoration: none;
}

.dropdownList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background: linear-gradient(45deg, #024884, #c11d24);
  /* border: 1px solid #222; */
  z-index: 2;
  border-radius: 5%;
}

.dropdownItem {
  padding: 0.5rem 1rem;
  color: white;
}

.dropdownItem:hover {
  background-color: #555;
  border-radius: 5%;
}
.side {
  color: skyblue !important;
  font-weight: 600;
  border-bottom: 0.5px solid skyblue;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.logo img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
}

/* for small and medium devices */

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }
  .navbar_sm {
    background: linear-gradient(45deg, #024884, #c11d24);
    padding: 10px 20px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
      sans-serif !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  .navbarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo_sm img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
  }
  .menu {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    display: flex;
  }
  .textIcon {
    margin-top: 5px;
  }

  .nav_links_sm {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #fff;
    padding: 5px;
    margin: 2px 0px -8px;
    transition: 1s;
  }

  .nav_links_sm a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
  }

  .dropdownList_sm {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    background: linear-gradient(45deg, #024884, #c11d24);
    /* border: 1px solid #222; */
    z-index: 2;
    border-radius: 5%;
  }
  .dropdownItem_sm {
    padding: 0.5rem 1rem;
    color: white;
  }
  .dropdownItem_sm:hover {
    background-color: #555;
    border-radius: 5%;
  }
}

@media screen and (min-width: 768px) {
  .navbar_sm {
    display: none;
  }
}
