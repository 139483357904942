.leaderContainer {
  /* border: 1px solid black; */
  background-color: #cfcece;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
}

.header {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #222;
  margin: 0px;
  padding: 25px;
  text-decoration: underline;
}

.container {
  box-sizing: border-box;
  /* border: 2px solid rgb(201, 27, 27); */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
}
