.container {
  background-color: rgb(23, 36, 27);
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.image1 {
  width: 60%;
  height: 80vh;
}

@media screen and (max-width: 768px) {
  .image1 {
    width: 100%;
    box-sizing: border-box;
    height: 55vh;
    object-fit: fill;
    /* border: 5px solid blue; */
  }
}
