.container {
  margin-top: -40px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  box-sizing: border-box;
  /* border: 5px solid green; */
}
.header {
  width: 100%;
  height: 90vh;
  position: relative;
}
.header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.header h1 {
  z-index: 1;
  position: relative;
  top: 30%;
  color: #fff;
  font-size: 56px;
  text-align: center;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
  animation-name: header-animation;
  animation-duration: 2s;
  animation-delay: 0.5s;
  /* animation-timing-function: ease-in-out; */
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes header-animation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(-30px); /* Move up */
  }
  60% {
    transform: translateY(-15px); /* Move up a little less */
  }
}

.content {
  background-color: #c9d8a1;
  padding-bottom: 60px;
}

.contentHeader {
  text-align: center;
  padding: 40px;
  padding-bottom: 20px;
}
.contentHeader h1 {
  font-size: 56px;
  font-weight: 800;
  line-height: 3.5rem;
  margin: 0px;
}
.contentHeader h2 {
  color: rgba(0, 0, 0, 0.65);
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainContent video {
  width: 65%;
  height: 100%;
  border-radius: 25px;
}

.footer {
  /* background-color: #0f3cc9; */
  background-color: #00364e;
  color: #fff;
  width: 100%;
  text-align: center;
  height: 300px;
}

.footer h1 {
  font-size: 56px;
  font-weight: 800;
  line-height: 3.5rem;
  margin: 0px;
  position: relative;
  top: 25%;
}

.footer p {
  position: relative;
  top: 28%;
  margin: 0;
}

.footbtnDiv {
  position: relative;
  top: 40%;
  /* border: 2px solid red; */
}
#phnNumdiv {
  position: absolute;
  /* border: 2px solid red; */
  left: 50%;
  /* top: 50%; */
  transform: translate(-50%);
  font-size: 22px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(101, 78, 230);
  width: 500px;
}

.footbtnDiv button {
  position: relative;
  margin: 0;
  font-size: 25px;
  border-radius: 15px;
  border: #fff;
}

.footbtnDiv button:hover {
  background-color: rgb(50, 182, 205);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }

  .header {
    width: 100%;
    height: 55vh;
    position: relative;
  }
  .header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }

  .header h1 {
    top: 12%;
  }

  .contentHeader {
    padding: 25px;
  }
  .contentHeader h1 {
    font-size: 40px;
    line-height: 3rem;
  }

  .mainContent video {
    width: 90%;
    height: 100%;
    border-radius: 25px;
  }
  .mainContent h2 {
    width: 90%;
  }
  .content {
    padding-bottom: 60px;
  }
  .footer h1 {
    font-size: 40px;
    line-height: 3rem;
    top: 20%;
  }
  .footer p {
    top: 23%;
  }

  .footbtnDiv {
    /* border: 3px solid green;  */
    top: 25%;
  }
  .footbtnDiv button {
    top: 0%;
    /* transform: translateY(-22%); */
  }

  #phnNumdiv {
    width: 300px;
  }
}
