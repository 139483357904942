.carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  /* border: 5px solid rgb(109, 179, 175); */
}

.carousel__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5rem;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s;
  opacity: 0.8;
}
.carousel__btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.carousel__btn_prev {
  left: 10px;
}

.carousel__btn_next {
  right: 10px;
}

.carousel__img:hover {
  transform: scale(1.05);
}

.carousel__indicators {
  position: absolute;
  z-index: 1;
  color: #d8d8d8;
  top: 90%;
  left: 50%;
  translate: -50%;
}

.balls {
  color: rgba(161, 24, 24, 0.8);
}

@media screen and (max-width: 768px) {
  .carousel {
    height: 100%;
    /* border: 5px solid rgb(229, 255, 0); */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel__indicators {
    position: absolute;
    z-index: 1;
    color: #d8d8d8;
    top: 90%;
    left: 50%;
    translate: -50%;
  }

  .carousel__btn_prev {
    left: 0px;
  }

  .carousel__btn_next {
    right: 0px;
  }
}
