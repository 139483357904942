.container {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial,
    sans-serif !important;
  box-sizing: border-box;
  /* border: 5px solid brown; */
  width: 100%;
}
.containerImg {
  height: 100vh;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.containerImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.CarouselDiv {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 5px solid green; */
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.aboutUs {
  text-align: center;
  padding: 1% 15% 8%;
  background: linear-gradient(45deg, grey, #e1d0b5);
  color: black;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.aboutUSAnimation {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
}
#animation1 {
  background-color: #3f61ed;
  animation: 3s linear 0s infinite alternate sun-rise1;
}
@keyframes sun-rise1 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(60vw, 120vh);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(-20vw, -20vh);
  }
}
#animation2 {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #ed3fb3;
  animation: 3s linear 0s infinite alternate sun-rise2;
}
@keyframes sun-rise2 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(-10vw, 110vh);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(80vw, -20vh);
  }
}
#animation3 {
  border-radius: 50%;
  background-color: #d39f11;
  animation: 3s linear 0s infinite alternate sun-rise3;
}
@keyframes sun-rise3 {
  from {
    /* pushes the sun down past the viewport */
    transform: translate(-35vw, 50vh);
  }
  to {
    /* returns the sun to its default position */
    transform: translate(100vw, 50vh);
  }
}
.aboutUs h1 {
  font-size: 60px;
  font-weight: 800;
  color: #fff;
  position: relative;
  z-index: 2;
}
.content {
  position: relative;
  z-index: 2;
  padding: 5% 10% 5%;
  font-size: 22px;
  border-radius: 30px;
  background: linear-gradient(45deg, pink, #f1f8fe, rgb(245, 247, 154));
  box-sizing: border-box;
  box-shadow: 6px 6px 3px lightseagreen;
  cursor: pointer;
  animation-name: card;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.content:hover,
.content:focus {
  animation-play-state: paused;
}

@keyframes card {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(-30px); /* Move up */
  }
  60% {
    transform: translateY(-15px); /* Move up a little less */
  }
}
.webDesignContainerSm {
  display: none;
}
.customPanelBackground {
  background: linear-gradient(125deg, #af6eed, #3f61ed);
  position: relative;
  height: 110vh;
  text-align: center;
  color: #fff;
  font-size: 35px;
  box-sizing: border-box;
}
.customPanelBackground h1 {
  margin: 0px;
}
.customPanel {
  /* border: 1px solid green; */
  margin-bottom: 100px;
  display: flex;
  height: 80vh;
  position: relative;
  justify-content: center;
  top: 2%;
  z-index: 2;
  box-sizing: border-box;
  overflow: hidden;
  margin: 30px;
  border-radius: 50px;

  cursor: pointer;
}

.customBackgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}

.customChild {
  /* border: 1px solid red; */
  box-shadow: 4px 4px 2px #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: #fff;
  position: relative;
  overflow: hidden;
  width: 33.33%;
}

.customChildHeading {
  display: flex;
  /* border: 1px solid blue; */
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
}

.customChildHeading h2 {
  font-size: 40px;
  margin: 0;
  margin-right: 10px;

  color: #04e4ff;
}
.customChildHeading h3 {
  font-size: 25px;
  margin: 0;
  text-align: left;
}

.customChildContent {
  text-align: center;
  font-size: 20px;
  padding: 10px;
  position: relative;
  bottom: -100%;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.5s, bottom 0.5s;
}

.visible {
  opacity: 1;
  bottom: 0;
}

.overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.overlayVisible {
  background-color: rgba(94, 94, 94, 0.795);
  cursor: pointer;
  opacity: 0.5;
}

.overlay:hover {
  background-color: rgba(94, 94, 94, 0.795);
  cursor: pointer;
  opacity: 0.5;
}

.techContainer {
  text-align: center;
  background: linear-gradient(45deg, #34d9df 20%, rgb(20, 20, 20) 40%);
  color: aliceblue;
  padding: 2%;
  box-sizing: border-box;
}

.techContainer h1 {
  font-size: 60px;
  font-weight: 800;
  margin: 0;
  padding: 25px;
  color: skyblue;
}

.techContentContainer {
  display: grid;
  grid-template-columns: 55% 45%;
  /* border: 2px solid green; */
  /* padding: 5%; */
  padding: 2% 5% 5%;
}

.techContent {
  grid-column: 1/2;
  /* border: 2px solid blue; */
  padding: 10px 25px 10px;
  font-size: 22px;
}

.techContent h1 {
  margin: 0;
  font-size: 40px;
  color: #f1f8fe;
}

/* .techContent h2 {
  font-size: 25px;
} */

.techContent p {
  padding: 0px 22px 22px;
  line-height: 31px;
  margin: 0;
}

.techImage {
  grid-column: 2/3;
  /* border: 2px solid green; */
  box-shadow: 4px 4px 2px rgb(35, 154, 209);
  /* background-color: #f1f8fe; */
  /* background: linear-gradient(180deg, rgb(202, 60, 60), rgb
  (28, 103, 133)); */
  background-color: #fffdea;
  border-radius: 50px;
}

.techImage img {
  width: 100%;
  height: 100%;
}

/* For small Screen */

@media screen and (max-width: 768px) {
  .container {
    margin-top: 62px;
  }
  .customPanelBackground {
    display: none;
  }
  .webDesignContainerSm {
    display: block;
    background: linear-gradient(125deg, #af6eed, #3f61ed);
    /* height: 100vh; */
    width: 100%;
    padding: 10px;
    /* border: 5px solid red; */
    box-sizing: border-box;
  }
  .webDesignContainerSm h1 {
    margin: 0px;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
  }

  .child {
    width: 100%;
    /* height: 50vh; */
    /* border: 5px solid brown; */
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 25px;
    position: relative;
    margin-bottom: 50px;

    padding: 8px;
    box-shadow: 0px 4px 2px rgb(196, 207, 40);
  }

  #child1 {
    background: linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  }

  #child2 {
    background: linear-gradient(45deg, #c94b4b, #4b134f);
  }

  #child3 {
    background: linear-gradient(45deg, #159957, #155799);
  }

  .child img {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    opacity: 0.8;
  }

  .childContent {
    position: relative;
    z-index: 2;
    color: #fffcfd;
    /* text-shadow: 2px 2px 1px black; */
    text-align: center;
    box-sizing: border-box;
  }
  .childContent p {
    font-size: 22px;
  }

  .containerImg {
    height: 55vh;
  }
  .containerImg img {
    object-fit: cover;
  }

  .CarouselDiv {
    width: 100%;
  }

  .aboutUs {
    padding: 15px;
    padding-bottom: 80px;
  }
  .aboutUs h1 {
    font-size: 45px;
  }

  .content {
    animation-play-state: paused;
    padding: 5%;
  }

  .techContainer h1 {
    font-size: 30px;
    padding: 10px;
  }
  .techContentContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .techContent {
    padding: 10px;
    font-size: 22px;
  }

  .techContent h1 {
    font-size: 30px;
    padding: 0px;
  }

  .techContent p {
    padding: 5px;
  }

  .techImage {
    grid-column: 2/3;
    /* border: 2px solid green; */
    box-shadow: 4px 4px 2px rgb(35, 154, 209);
    /* background-color: #f1f8fe; */
    /* background: linear-gradient(180deg, rgb(202, 60, 60), rgb
  (28, 103, 133)); */
    background-color: #fffdea;
    border-radius: 50px;
  }

  .techImage img {
    width: 100%;
    height: 100%;
  }
}
